// NOTE: 本当はid系はnumberにしたい。だけどパスパラメーターで使っているところでstringである必要がある。
// NOTE: そのためあえて id: string;を統一して選択

// Common types
type ID = string;
type DisplayOrder = number;

// Utility types
type ValueOf<T> = T[keyof T];

// Base interfaces
interface BaseCategory {
  id: ID;
  key: string;
  name: string;
}

interface BaseType {
  id: ID;
  name: string;
  key: string;
  display_order: DisplayOrder;
}

// Search area related
export interface SearchAreaCategory extends BaseCategory {}

export interface SearchArea {
  id: ID;
  name: string;
  display_order: DisplayOrder;
  search_area_category_id: ID;
}

// Service type related
export interface ServiceTypeCategory extends BaseCategory {}

export interface ServiceType extends BaseType {
  service_type_category_id: ID;
}

// Occupation type related
export interface OccupationTypeCategory extends BaseCategory {}

export interface OccupationType extends BaseType {
  occupation_type_category_id: ID;
}

// Category collections
export type SearchAreaCategories = Record<string, SearchAreaCategory>;
export type ServiceTypeCategories = Record<string, ServiceTypeCategory>;
export type OccupationTypeCategories = Record<string, OccupationTypeCategory>;

export interface Masters {
  searchAreaCategories: SearchAreaCategory[];
  searchAreas: SearchArea[];
  serviceTypeCategories: ServiceTypeCategory[];
  serviceTypes: ServiceType[];
  occupationTypeCategories: OccupationTypeCategory[];
  occupationTypes: OccupationType[];
}

// Search condition
export interface Condition {
  occupation_type_ids: ID[];
  search_area_ids: ID[];
  service_type_ids: ID[];
}

export interface Prefecture {
  id: ID;
  name: string;
}

export interface JobServiceType {
  id: ID;
  job_id: ID;
  service_type_id: ID;
  service_types: ServiceType;
}

export interface Job {
  id: ID;
  job_id: ID;
  job_key: string;
  title: string;
  is_company_not_public: 0 | 1; // Literal type better safety
  company_name: string;
  salary_min: number;
  salary_max: number;
  salary_display_type: 1 | 2; // Literal type better safety
  location_city: string | null;
  holiday_schedule: string | null;
  prefectures: Prefecture;
  occupation_types: OccupationType;
  job_service_types: JobServiceType[];
}

export interface MastersAndJobs extends Masters {
  pagingJobs: Job[];
  jobsCount: number;
  parameterOccupationTypeIds: ID[];
  parameterSearchAreaIds: ID[];
  parameterServiceTypeIds: ID[];
  currentPage: number;
  currentEnvironment: Environment;
  pageSize: number;
}

export const Environments = {
  PRODUCTION: "production",
  PREVIEW: "preview",
  LOCAL: "local",
} as const;

export type Environment = ValueOf<typeof Environments>;

// Job payment & ocation related
export type JobPaymentInfo = {
  salary_min: number;
  salary_max: number;
  salary_display_type: 1 | 2;
};

export type JobLocationInfo = {
  location_city: string | null;
  prefectures: {
    name: string;
  };
};

export type ResultsListProps = {
  pagingJobs: Job[];
  masters: Masters;
  currentEnvironment: Environment;
};
